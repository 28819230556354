import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './GlyphInput.css';

const getAngle = (cx, cy, ex, ey) => {
    let dy = ey - cy;
    let dx = ex - cx;
    let theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    //if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
}

let ref;
export const GlyphInput = ({onChange, bgc}) => {
    const [outerRotation, setOuterRotation] = useState(-60);
    const [innerRotation, setInnerRotation] = useState(90);
    const [outer, setOuter] = useState();
    const [center, setCenter] = useState([0, 0]);
    const [angle, setAngle] = useState();

    useEffect(
        () => onChange([outerRotation, innerRotation]),
        [onChange, outerRotation, innerRotation]
    )

    const getCenter = () => {
        if (center[0] || center[1]) return center;
        if (ref) {
            let rect = ReactDOM.findDOMNode(ref).getBoundingClientRect();
            const newCenter = [rect.left + (rect.width/2), rect.top + (rect.height/2)];
            setCenter(newCenter);
            return newCenter
        }
    }

    const handleAngle = (e) => {
        const center = getCenter();
        const loc = [
            e.touches[0].clientX,
            e.touches[0].clientY
        ]
        const newAngle = getAngle(...center, ...loc);
        if (angle !== undefined){
            const setter = outer ? setOuterRotation : setInnerRotation;
            const oldRotation = outer ? outerRotation : innerRotation;
            let newRotation = oldRotation - angle + newAngle;
            if (newRotation > 360)
                newRotation -= 360;
            else if (newRotation < 0)
                newRotation += 360;
            setter(newRotation);
        }
        setAngle(newAngle);
        return newAngle;
    }

    const onTouchStart = (e, outer) => {
        setOuter(outer);
        handleAngle(e);
    }
    const onTouchMove = (e) => handleAngle(e);
    const onTouchEnd = () => setAngle(undefined);

    return (
        <div className="glyphInput"
             style={{
                 borderRadius: '100%',
                 backgroundColor: bgc
             }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-15 5 130 130"
                className={"glyph noPointer"}
                ref={node => ref = node}
            >
                <g>
                    <mask id="spinnyMask">
                        <rect x="-10" y="0" width="200" height="200" fill="white"/>
                        <polygon
                            transform={`rotate(${outerRotation})`}
                            points="50 13.397, 100 100, 0 100"
                            className={"glyph__outer"}
                            fill="black"
                        />
                    </mask>
                    <circle
                        cx="50"
                        cy="71.1"
                        r="58"
                        mask="url(#spinnyMask)"
                        className={"pointer"}
                        onTouchStart={e => onTouchStart(e, true)}
                        onTouchMove={e => onTouchMove(e)}
                        onTouchEnd={e => onTouchEnd(e)}
                    />
                    <polygon
                        transform={`rotate(${outerRotation})`}
                        className={"glyph__outer pointer"}
                        points="50 13.397, 100 100, 0 100"
                        fill="none"
                    />
                    <rect
                        transform={`rotate(${innerRotation + 45})`}
                        height="40.8"
                        width="40.8"
                        x="29.6"
                        y="50.6"
                        className={"glyph__inner pointer"}
                        fill="black"
                        onTouchStart={e => onTouchStart(e, false)}
                        onTouchMove={e => onTouchMove(e)}
                        onTouchEnd={e => onTouchEnd(e)}
                    />
                </g>
            </svg>
        </div>
    );
}

