import React, {useState} from "react";
import {GlyphInput} from "./components/Glyph/GlyphInput";

function App() {
    // const [rotations, setRotations] = useState([0, 0]);
    const [h, setH] = useState(0);
    const [l, setL] = useState(0);

    return (
        <div className="App">
            {/*<p>{rotations.map(r => `${r}`.substr(0, 4)).join(' : ')}</p>*/}
            {/*<p>h: {h}</p>*/}
            {/*<p>l: {l}</p>*/}
            {/*<div*/}
            {/*    style={{*/}
            {/*        backgroundColor: `hsl(${h}, 100%, ${l}%)`*/}
            {/*    }}*/}
            {/*>*/}
            {/*</div>*/}
            <GlyphInput
                onChange={rotations => {
                    // setRotations(rotations);
                    setH(rotations[0]);
                    setL(Math.abs((((rotations[1] - 180)) / 18) * 10))
                }}
                bgc={`hsl(${h}, 100%, ${l}%)`}
            />
        </div>
  );
}

export default App;
